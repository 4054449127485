import Image from 'next/image';
import Link from 'next/link';

import { IconWithLabel, TextKeyValuePair } from '@/components/atomic/atoms';
import { staticMediaStoreBaseURL } from '@/config/common';
import { PARTNER_TIER } from '@/services/home.service';
import { PAGE_NAME, getPageURL } from '@/services/partnerPortal.service';

const HomePageHeaderInfoMobile = ({
  emailId,
  legalNameOfPartnerOrg,
  mobileNumber,
  partnerTier
}) => {
  const iconwithLabelData = [
    {
      containerClass: 'flex gap-2 mt-3 md:mt-5',
      imgAlt: 'phone',
      imgSrc: 'call-white.svg',
      value: mobileNumber,
      valueStyle: 'text-sm font-medium text-white self-center'
    },
    {
      containerClass: 'flex gap-2 mt-3 mb-4 md:mb-0 pb-2',
      imgAlt: 'email',
      imgSrc: 'email-icon.svg',
      value: emailId,
      valueStyle: 'text-sm font-medium text-white self-center'
    }
  ];

  const {
    icon,
    label,
    starIcon,
    valueStyle: valueClassName
  } = PARTNER_TIER[partnerTier];

  return (
    <div className='bg-brand-gradient mx-auto rounded-b-3xl md:rounded-b-6.25 px-5 md:px-8 pt-6 md:pt-6 h-72 md:h-60'>
      <div className='flex flex-col md:w-47.5 mx-auto'>
        <div className='flex justify-between'>
          <div className='flex gap-4'>
            <Image
              alt='status-icon'
              height={0}
              priority={true}
              src={`${staticMediaStoreBaseURL}/icons/${icon}`}
              style={{ height: 82, width: 60 }}
              width={0}
            />
            <div className='self-end'>
              <TextKeyValuePair
                {...{
                  label: `Hi ${legalNameOfPartnerOrg}`,
                  labelClass:
                    'text-xl font-semibold text-white truncate max-w-36 md:max-w-100',
                  value: 'Welcome to Hafla 🎉',
                  valueClassName: 'text-sm font-medium text-white',
                  className: 'flex-col'
                }}
              />
            </div>
          </div>
          <Link href={getPageURL({ pageName: PAGE_NAME.PROFILE.label })}>
            <IconWithLabel
              {...{
                containerClass:
                  'flex items-center gap-1 border border-white py-1 rounded-lg cursor-pointer px-3 md:px-5 shadow-card',
                imgAlt: 'eye',
                imgHeight: 16,
                imgSrc: 'view-icon.svg',
                imgWidth: 16,
                value: 'Profile',
                valueStyle: 'text-sm font-medium text-white'
              }}
            />
          </Link>
        </div>
        <IconWithLabel
          {...{
            containerClass:
              'flex gap-2 bg-black self-baseline px-4 py-1 rounded-full mt-4',
            imgAlt: 'star',
            imgHeight: 18,
            imgSrc: `${starIcon}`,
            imgWidth: 18,
            value: `${label} partner`,
            valueStyle: `text-sm font-medium ${valueClassName} self-center`
          }}
        />
        {iconwithLabelData.map(
          (
            {
              containerClass,
              imgAlt,
              imgHeight,
              imgSrc,
              imgWidth,
              value,
              valueStyle
            },
            index
          ) => (
            <IconWithLabel
              key={index}
              {...{
                containerClass,
                imgAlt,
                imgHeight,
                imgSrc,
                imgWidth,
                value,
                valueStyle
              }}
            />
          )
        )}
      </div>
    </div>
  );
};

export default HomePageHeaderInfoMobile;
